import React, { useEffect } from 'react'
import TopNav from '../bars/TopNav'
import landing1 from '../image/Landing1.png'
import Landing2 from '../image/Landing2.png'
import Landing3 from '../image/Landing3.png'
import Email from '../svg/Email.svg'
import Social from '../svg/Social.svg'
import Digital from '../svg/Digital.svg'
import Voice from '../svg/Voice.svg'
import face from '../image/Facebook.png'
import what from '../image/Whatsapp.png'
import insta from '../image/Instagram.png'
import twit from '../image/layer1.png'
import Valet from '../svg/Valet.svg'
import Health from '../svg/Health.svg'
import Jeep from '../svg/Jeep.svg'
import Bank from '../svg/Bank.svg'
import Finance from '../svg/Finance.svg'
import Real from '../svg/Real.svg'
import Edu from '../svg/Edu.svg'
import Gov from '../svg/Gov.svg'
import Ecom from '../svg/Ecom.svg'
import Fast from '../svg/Fast.svg'
import Ucx from '../image/ImgUcx.png'
import Network from '../image/Network.png'
import Conversion from '../image/Conversion.png'
import United from '../image/United.png'
import Footer from '../bars/Footer'
import ticket from '../image/landingticket.png'
import smart from '../image/landingsurvey.png'
import chatIcon from '../image/landingchat.png'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom'

import dccDb1Img from "./products/images/digitalWorkSpace1.png"
import dccDb2Img from "./products/images/digitalWorkSpace2.png"

import OmniDb1Img from "../image/omni/omniDb1.png"
import OmniDb2Img from "../image/omni/omniDb2.png"
import OmniDb3Img from "../image/omni/omniDb3.png"

import HeroImg from "../image/heroImg/landing_image1.png"
import HeroIcon1 from "../image/heroImg/landingicon1.png"
import HeroIcon2 from "../image/heroImg/landingicon2.png"
import HeroIcon3 from "../image/heroImg/landingicon3.png"
import HeroIcon4 from "../image/heroImg/landingicon4.png"
import HeroIcon5 from "../image/heroImg/landingicon5.png"
import HeroIcon6 from "../image/heroImg/landingicon6.png"
import HeroIcon7 from "../image/heroImg/landingicon7.png"
import HeroIcon8 from "../image/heroImg/landingicon8.png"
import HeroIcon9 from "../image/heroImg/landingicon9.png"
 

function Landing() {

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, []);
    return (
        <div>
            <TopNav />
                <div className="hidden lg:block absolute bgSect1 top-16 w-full h-64 landing-page"></div>

                <div className='2xl:mt-[135px] xl:mt-[120px] mt-28 top_section flex lg:flex-row lg:space-x-4 flex-col'>
                    <div className='flex flex-col lg:w-1/2'>
                        <div data-aos="fade-up" className='text-[18px] mx-auto lg:mx-0 lg:text-left text-center text-[#006FFF] px-6 py-3 bg-white rounded-full w-full lg:w-fit font-semibold z-10 relative'><span> CLOUD CONTACT CENTER PLATFORM​</span> 
                        <div className="absolute left-0 top-0 lg:flex hidden"><span class="relative flex h-3 w-3">
                        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                        <span class="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                        </span></div></div>
                        <h1  data-aos="fade-up" className='z-10 mt-2 lg:text-left text-center'>Business Communications <span className='text-[#006FFF] whitespace-nowrap'>Made Effortless</span></h1>
                        <p  data-aos="fade-up" className='mt-5 w-11/12 text-[#797979] lg:text-left text-center mx-auto lg:mx-0'>
                             Empower your business to deliver seamless, personalized, and efficient customer interactions. Enhance customer satisfaction, improve response times, and streamline your operations with our hassle-free, always-on Digital Contact Center.
                        </p>

                        <a href='#/contact' className="demo mt-6 w-fit text-center lg:justify-start justify-center lg:mx-0 mx-auto">
                            Request a Demo
                        </a>

                    </div>
                    <div  data-aos="zoom-in" className='land lg:mt-3 mt-8 lg:w-1/2 relative xl:top-[-20px] lg:flex hidden custom-margin hero-animation'>
                         <img className="absolute  top-0 left-0 " src={HeroImg} alt="" />
                         <img className="absolute  layer1 layer" src={HeroIcon1} alt="" />
                         <img className="absolute  layer2 layer" src={HeroIcon2} alt="" />
                         <img className="absolute  layer3 layer" src={HeroIcon3} alt="" />
                         <img className="absolute  layer4 layer" src={HeroIcon4} alt="" />
                         <img className="absolute  layer5 layer" src={HeroIcon5} alt="" />
                         <img className="absolute  layer6 layer" src={HeroIcon6} alt="" />
                         <img className="absolute  layer7 layer" src={HeroIcon7} alt="" /> 
                         <img className="absolute  layer8 layer" src={HeroIcon8} alt="" />
                         <img className="absolute  layer9 layer" src={HeroIcon9} alt="" />
                     </div>
                    <img data-aos="zoom-in" className='lg:hidden flex justify-center land lg:mt-0 mt-12 mx-auto lg:w-1/2 shrink-0 relative' src={landing1} alt='landing' />

                 </div>


            <div className="landing-page-container">
                <div className='mt-[80px] bg-[#EFF3FF] lg:py-[60px] py-10'>
                    <div className='top_section'>
                        <h2  data-aos="fade-up" className='text-center'><span className='text-[#006FFF]'>Engaging Customers</span><br /> Across All Touchpoints​</h2>
                        <div className='mt-[44px] w-full'>
                            <div className='hidden lg:block'>
                                <Carousel className='h-fixed'
                                    infiniteLoop={true}
                                    stopOnHover
                                    autoPlay
                                    transitionTime={1000}
                                    showThumbs={false}
                                    showArrows={true}
                                    showStatus={false}
                                    swipeable
                                    dynamicHeight={false}
                                    show={1.8} // Set the number of cards to display (1.5 will show one full card and half of another)

                                    centerMode

                                    centerSlidePercentage={65}
                                >
                                    <div className="cardCus min-h-[320px] mr-2 justify-between">
                                        <div className='flex-col items-start w-1/3'>
                                            <img className='w-20 h-20' src={Digital} alt='digital' />
                                            <div className='text-base lg:text-[20px] font-semibold pt-3'>Digital</div>
                                            <div className='text-[#717171] mt-4 lg:text-base text-xs'>AI Chatbot​​</div>
                                        </div>
                                        <ul className='text-left text-base font-medium w-3/4 card-inner-card mr-4'>
                                            <div className='flex space-x-2 mt-1 items-center'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0'></div>
                                                <div>24*7 Instant Customer Support</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-center'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0'></div>
                                                <div>Multilingual Support</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-center'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0'></div>
                                                <div>Evolutionary AI-driven Adaptive Learning</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-center'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0'></div>
                                                <div>Live Agent Assistance</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-center'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0'></div>
                                                <div>Lead Generation</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-center'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0'></div>
                                                <div>Customer Data-Driven Analytics</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-center'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0'></div>
                                                <div>Effortless Integration</div>
                                            </div>
                                        </ul>
                                    </div>
                                    <div className="cardCus min-h-[320px] mr-2">
                                        <div className='flex-col items-start w-1/3'>
                                            <img className='w-20 h-20' src={Voice} alt='voice' />
                                            <div className='text-base lg:text-[20px] font-semibold pt-3'>Voice</div>
                                            <div className='text-[#717171] mt-4 lg:text-base text-xs'>Avaya</div>
                                        </div>
                                        <ul className='text-left text-base font-medium w-3/4 card-inner-card mr-4'>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Leverage Avaya's ACD core engine for voice channel capabilities with additional value-adds</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Features: Inbound/outbound voice, advanced IVR, ACD call control, call recordings, etc</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Voice-to-digital deflection lets agents transfer a call to WhatsApp chat</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Composable desktop with screen popups for unified customer journeys</div>
                                            </div>
                                        </ul>
                                    </div>

                                    <div className="cardCus mr-2  min-h-[320px]">
                                        <div className='flex-col items-start  w-1/3'>
                                            <img className='w-20 h-20' src={Social} alt='social' />
                                            <div className='text-base lg:text-[20px] font-semibold pt-3'>Social Media</div>
                                            <div className='text-[#717171] flex space-x-3 justify-center items-center pt-4'>
                                                <div className="shrink-0">
                                                    <img className='w-[15.998px] h-4' src={face} alt='facebook' />
                                                </div>
                                                <div className="shrink-0">
                                                    <img className='w-[15.998px] h-4' src={what} alt='whatsapp' />
                                                </div>
                                                <div className="shrink-0">
                                                    <img className='w-4 h-4' src={insta} alt='instagram' />
                                                </div>
                                                <div className="shrink-0">
                                                    <img className='w-[15.671px] h-[14.667px]' src={twit} alt='twitter' />
                                                </div>
                                            </div>
                                        </div>
                                        <ul className='text-left text-base font-medium w-3/4 card-inner-card mr-4 h-[230px] overflow-y-auto'>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Manage multiple social media channels in one interface</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Real-time response</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Improved agent experience and efficiency</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Integrate social media chats for direct customer-agent chat</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Route social media comments to agents for responses</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Integrate social media notifications with Contact Center chat</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Seamless Integration</div>
                                            </div>
                                        </ul>
                                    </div>
                                    <div className="cardCus mr-2  min-h-[320px]">
                                        <div className='flex-col items-start w-1/3'>
                                            <img className='w-16 h-16' src={Email} alt='email' />
                                            <div className='text-base lg:text-[20px] font-semibold pt-3'>Email & SMS</div>
                                            <div className='text-[#717171] mt-4 lg:text-base text-xs'>Email and SMS Gateways​</div>
                                        </div>
                                        <ul className='text-left text-base font-medium w-3/4 card-inner-card mr-4'>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Intuitive Interface</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Multilingual Support</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Send individualized and bulk messages</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Schedule messages and send later</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>SMS Gateway to keep track of SMS</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Email Gateway to monitor sent and received emails</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Fully Customizable Solution</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Integrate with third-party applications and database</div>
                                            </div>
                                        </ul>
                                    </div>

                                </Carousel>
                            </div>
                            <div className=' lg:hidden block'>
                                <Carousel
                                 transitionTime={1000}
                                 showThumbs={false}
                                 showArrows={true}
                                 showStatus={false}
                                 swipeable
                                 dynamicHeight={false}
                                     
                                >
                                    <div className="cardCus mr-2 h-full">
                                        <div className='flex-col items-start'>
                                            <img className='w-20 h-20' src={Digital} alt='digital' />
                                            <div className='text-base lg:text-[20px] font-semibold pt-3'>Digital</div>
                                            <div className='text-[#717171] mt-4 lg:text-base text-xs'>AI Chatbot​​</div>
                                        </div>
                                        <ul className='text-left text-base font-medium card-inner-card'>
                                            <div className='flex space-x-2 mt-1 items-center'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0'></div>
                                                <div>24*7 Instant Customer Support</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-center'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0'></div>
                                                <div>Multilingual Support</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-center'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0'></div>
                                                <div>Evolutionary AI-driven Adaptive Learning</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-center'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0'></div>
                                                <div>Live Agent Assistance</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-center'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0'></div>
                                                <div>Lead Generation</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-center'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0'></div>
                                                <div>Customer Data-Driven Analytics</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-center'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0'></div>
                                                <div>Effortless Integration</div>
                                            </div>
                                        </ul>
                                    </div>
                                    <div className="cardCus mr-2 h-full">
                                        <div className='flex-col items-start'>
                                            <img className='w-20 h-20' src={Voice} alt='voice' />
                                            <div className='text-base lg:text-[20px] font-semibold pt-3'>Voice</div>
                                            <div className='text-[#717171] mt-4 lg:text-base text-xs'>Avaya</div>
                                        </div>
                                        <ul className='text-left text-base font-medium card-inner-card'>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Leverage Avaya's ACD core engine for voice channel capabilities with additional value-adds</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Features: Inbound/outbound voice, advanced IVR, ACD call control, call recordings, etc</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Voice-to-digital deflection lets agents transfer a call to WhatsApp chat</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Composable desktop with screen popups for unified customer journeys</div>
                                            </div>
                                        </ul>
                                    </div>

                                    <div className="cardCus mr-2 h-full">
                                        <div className='flex-col items-start'>
                                            <img className='w-20 h-20' src={Social} alt='social' />
                                            <div className='text-base lg:text-[20px] font-semibold pt-3'>Social Media</div>
                                            <div className='text-[#717171] flex space-x-3 justify-center items-center mt-4'>
                                                <div className="shrink-0">
                                                    <img className='w-[15.998px] h-4' src={face} alt='facebook' />
                                                </div>
                                                <div className="shrink-0">
                                                    <img className='w-[15.998px] h-4' src={what} alt='whatsapp' />
                                                </div>
                                                <div className="shrink-0">
                                                    <img className='w-4 h-4' src={insta} alt='instagram' />
                                                </div>
                                                <div className="shrink-0">
                                                    <img className='w-[15.671px] h-[14.667px]' src={twit} alt='twitter' />
                                                </div>
                                            </div>
                                        </div>
                                        <ul className='text-left text-base font-medium card-inner-card '>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Manage multiple social media channels in one interface</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Real-time response</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Improved agent experience and efficiency</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Integrate social media chats for direct customer-agent chat</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Route social media comments to agents for responses</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Integrate social media notifications with Contact Center chat</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Seamless Integration</div>
                                            </div>
                                        </ul>
                                    </div>
                                    <div className="cardCus mr-2 h-full">
                                        <div className='flex-col items-start'>
                                            <img className='w-16 h-16' src={Email} alt='email' />
                                            <div className='text-base lg:text-[20px] font-semibold'>Email & SMS</div>
                                            <div className='text-[#717171] mt-4 lg:text-base text-xs'>Email and SMS Gateways​</div>
                                        </div>
                                        <ul className='text-left text-base font-medium card-inner-card'>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Intuitive Interface</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Multilingual Support</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Send individualized and bulk messages</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Schedule messages and send later</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>SMS Gateway to keep track of SMS</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Email Gateway to monitor sent and received emails</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Fully Customizable Solution</div>
                                            </div>
                                            <div className='flex space-x-2 mt-1 items-start'>
                                                <div className='w-1 h-1 rounded-full bg-black shrink-0 mt-2'></div>
                                                <div>Integrate with third-party applications and database</div>
                                            </div>
                                        </ul>
                                    </div>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='  bg-gradient-to-b from-[#78adfd] to-[#eef5ff]  rounded-3xl py-16 mt-16'>
                    <h2 data-aos="fade-up" className='text-center text-white'>Why Inaipi?</h2>
                    <div className='top_section mt-10 lg:mt-[60px] w-full flex lg:flex-row lg:space-x-8 flex-col justify-center lg:justify-between items-center'>
                        <img src={Ucx} alt='ucx' className='fea lg:w-[588px] w-[343px] h-[317px] lg:h-[544px] mx-auto lg:mx-0' />
                        <div className='flex flex-col space-y-2 mt-4 lg:mt-0'>
                            <div data-aos="fade-right"className='flex md:flex-row flex-col md:items-center md:gap-6 gap-2 lg:px-6 px-4 lg:py-3 py-6 rounded-xl bg-[#3988ff]'>
                                <div className='relative lg:w-[80px] lg:h-[80px] w-[50px] h-[50px] bg-white rounded-full flex items-center justify-center shrink-0'>
                                    <img className='lg:w-[56px] lg:h-[56px] w-[24.51px] h-[24.51px] absolute' src={Network} alt='Network' />
                                </div>
                                <div className='flex flex-col space-y-1'>
                                    <div className='text-[24px] font-semibold text-white'>Connect</div>
                                    <p className='text-[#FFF] text-sm'>Connect with your team and clients effortlessly. Our software provides a single platform to connect through all channels. Say goodbye to the hassle of switching between different apps to communicate.</p>
                                </div>
                            </div>
                            <div data-aos="fade-right" className='flex md:flex-row flex-col md:items-center md:gap-6 gap-2 lg:px-6 px-4 lg:py-3 py-6 rounded-xl bg-[#3988ff]'>
                                <div className='relative lg:w-[80px] lg:h-[80px] w-[50px] h-[50px] bg-white rounded-full flex items-center justify-center shrink-0'>
                                    <img className='lg:w-[56px] lg:h-[56px] w-[24.51px] h-[24.51px] absolute' src={Conversion} alt='conversion' />
                                </div>
                                <div className='flex flex-col space-y-1'>
                                    <div className='text-[24px] font-semibold text-white'>Converse</div>
                                    <p className='text-[#FFF] text-sm'>Converse with your team and clients like never before. Our software lets you have real-time conversations across different channels with ease.</p>
                                </div>
                            </div>
                            <div data-aos="fade-right" className='flex md:flex-row flex-col md:items-center md:gap-6 gap-2 lg:px-6 px-4 lg:py-3 py-6 rounded-xl bg-[#3988ff]'>
                                <div className='relative lg:w-[80px] lg:h-[80px] w-[50px] h-[50px] bg-white rounded-full flex items-center justify-center shrink-0'>
                                    <img className='lg:w-[56px] lg:h-[56px] w-[24.51px] h-[24.51px] absolute' src={United} alt='united' />
                                </div>
                                <div className='flex flex-col space-y-1'>
                                    <div className='text-[24px] font-semibold text-white'>Collaborate</div>
                                    <p className='text-[#FFF] text-sm'>Collaborate with your team and clients efficiently. Our software lets you collaborate in real-time, share files, and access information quickly.</p>
                                </div>
                            </div>
                            <div className="flex justify-end text-[#ffffff] h-10 w-full relative">
                            <Link data-aos="fade-right" to="../digital-contact-center" class="flex items-center space-x-2 px-8 py-6 bg-[#3988ff] text-white font-semibold rounded-md transition-transform duration-300 hover:bg-[#1071df] group">
                                <span>Discover More</span>
                                <span class="transform transition-transform duration-300 group-hover:translate-x-1">&rarr;</span>
                            </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='top_section flex flex-col justify-center items-center lg:mt-[80px] mt-14 py-[80]'>
                    <h3 data-aos="fade-up" className='text-center' style={{textTransform:"none"}}>Improve Your Customer Service with Our<br /> User-Friendly <span className='text-[#006FFF]'>AI-Powered Solutions</span></h3>
                    <div className='mt-12 grid grid-flow-row md:grid-cols-3 grid-cols-1 w-11/12 gap-x-6 md:gap-y-14 gap-y-8'>
                        <Link data-aos="zoom-in" to='../ai-chatbot' className="max-h-[350px] pro flex flex-col cursor-pointer bg-custom-gradient" style={{ borderRadius: '16px', border: '1px solid #F0F0F0' }}>
                            <img className='max-h-[200px] mx-auto' src={chatIcon} alt='ticket' />
                            <div className='productH text-center pt-4 pb-6'>Al Chatbot</div>
                        </Link>
                        <Link data-aos="zoom-in" to='../ticketing-system' className="max-h-[350px] pro flex flex-col cursor-pointer bg-custom-gradient" style={{ borderRadius: '16px', border: '1px solid #F0F0F0' }}>
                            <img className='max-h-[200px] mx-auto' src={ticket} alt='ticket' />
                            <div className='productH text-center pt-4 pb-6'>Ticketing System</div>
                        </Link>
                        <Link data-aos="zoom-in" to='../smart-survey' className="max-h-[350px] pro flex flex-col cursor-pointer bg-custom-gradient" style={{ borderRadius: '16px', border: '1px solid #F0F0F0' }}>
                            <img className='max-h-[200px] mx-auto' src={smart} alt='smart' />
                            <div className='productH text-center pt-4 pb-6'>Smart Survey</div>
                        </Link>
                       
                    </div>
                    <a href='https://calendly.com/avayacentrum-demo/inaipi' target='_blank' className='mt-10 rounded text-white sm:text-base text-sm font-semibold px-6 py-4 bg-[#006FFF] hover:bg-[#224ed4]'>Request a Demo</a>

                </div>

                <div className='lg:mt-[100px] mt-14 top_section'>
                    <h2 style={{textTransform:"none"}} className='text-center'>Transforming CX  with<br></br> <span className='text-[#006FFF]'>Intelligent Omnichannel Engagement Platform!</span>​</h2>
                    <p className='text-center text-[#797979] pt-10' style={{textAlign:"center"}}>Modernize customer experience with digital assistants, chatbots, robot, social messaging channels,<br></br> smart routing, social listening & analytics providing a unique and outstanding experience. ​</p>
                </div>
                <div className='lg:mt-[120px] mt-14 top_section lg:text-left text-center'>
                    <div className='flex lg:flex-row flex-col-reverse items-center lg:space-x-16'>
                        {/* <img src={Landing2} alt='Landing2' className='fea w-full sm:w-[612px] lg:w-1/2 sm:h-[400px] lg:mt-0 mt-5' /> */}
                        <div data-aos="zoom-in" className="md:w-[60%] w-full h-[200px] relative md:my-0 my-6">
                            <div className="absolute left-0 w-[60%] zoom-image">
                                <img src={dccDb2Img} alt="" />
                            </div>
                            <div className="absolute right-[0%] top-1/3 w-[60%] zoom-image">
                                <img src={dccDb1Img} alt="" />
                            </div>
                        </div>
                        <div className='flex flex-col lg:w-1/2 w-full'>
                            <h3 data-aos="fade-up" >Maximize the possibilities of the platforms you already have​</h3>
                            <p className='pt-8 text-[#797979]'>Inaipi DCC connects with the top CRMs, e-commerce platforms, help desk applications, and marketing tools. You don't have to worry about the coding because we did it. ​</p>
                        </div>
                    </div>
                </div>
                <div className='lg:mt-[120px] mt-0 top_section lg:text-left text-center'>
                    <div className='flex lg:flex-row flex-col items-center lg:space-x-16 lg:space-y-10'>
                        <div className='flex flex-col lg:w-1/2 w-full'>
                            <h3 data-aos="fade-up">Revitalizing the Customer Experience with an Omnichannel Contact Center​​</h3>
                            <p className='pt-8 text-[#797979]'>With the Inaipi DCC solution, businesses can communicate with customers through multiple channels for a seamless experience and gain insights into their behavior. Customers can choose their preferred method, leading to higher satisfaction levels. Additionally, the solution centralizes customer interactions to boost agent efficiency and enable them to handle multiple queries with ease​.​​</p>
                        </div>
                        <div data-aos="zoom-in" className="md:w-[50%] w-full h-[200px] relative lg:my-0 my-12">
                            <div className="absolute left-0 w-[60%] zoom-image">
                                <img src={OmniDb1Img} alt="" />
                            </div>
                            <div className="absolute right-[20%] top-1/4 w-[60%] zoom-image">
                                <img src={OmniDb2Img} alt="" />
                            </div>
                            <div className="absolute right-[0%] top-1/2 w-[60%] zoom-image">
                                <img src={OmniDb3Img} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center items-center mx-auto lg:pt-16 pt-10">
                    <a href='#/feature' className='border border-[#006FFF] hover:border-[#224ed4] hover:text-[#224ed4] rounded-lg text-[#006FFF] py-4 px-6 text-base font-semibold'>See all</a>
                </div>
                <div className='lg:my-[80px] my-14 mt-14 lg:mt-[80px] rounded-3xl bg-[#F6F7FF] py-20'>
                    <div className='top_section flex flex-col lg:flex-row items-center lg:space-x-16 space-x-0 justify-center'>
                        <div className='flex flex-col'data-aos="fade-up">
                            <h2  className='lg:text-left text-center'>Increase customer satisfaction with <br /><span className='text-[#006FFF]'>Inaipi Platform</span></h2>
                            <p className='lg:text-left text-center text-[#797979] pt-8 w-full lg:w-[80%]'>No matter your industry, we grow with you to meet your unique requirements.</p>
                        </div>
                        <div className='meet mt-[41px] lg:mt-0 grid grid-flow-row grid-cols-3 md:grid-cols-4 lg:gap-4 md:gap-y-10 md:gap-x-10 gap-6 lg:w-full justify-items-center'>
                            <div data-aos="fade-right" className='relative lg:w-[150px] lg:h-[150px] w-[80px] h-80px] bg-white rounded-full flex items-center justify-center shrink-0'>
                                <div className='ucxCard flex flex-col space-y-2 lg:space-y-4 justify-center items-center mx-auto lg:h-[150px] lg:w-[150px] w-20 h-20 shrink-0'>
                                    <img alt='valet' className='lg:w-12 lg:h-12 w-6 h-6' src={Valet} />
                                    <div className='ucxH'>Hospitality</div>
                                </div>
                            </div>
                            <div data-aos="fade-right" className='relative lg:w-[150px] lg:h-[150px] w-[80px] h-[80px] bg-white rounded-full flex items-center justify-center shrink-0'>
                                <div className='ucxCard flex flex-col space-y-2 lg:space-y-4 justify-center items-center mx-auto lg:h-[150px] lg:w-[150px] w-20 h-20 shrink-0'>
                                    <img className='lg:w-12 lg:h-12 w-6 h-6' src={Health} alt='health' />
                                    <div className='ucxH'>Healthcare​</div>
                                </div>
                            </div>
                            <div data-aos="fade-right" className='relative lg:w-[150px] lg:h-[150px] w-[80px] h-80px] bg-white rounded-full flex items-center justify-center shrink-0'>
                                <div className='ucxCard flex flex-col space-y-2 lg:space-y-4 justify-center items-center mx-auto lg:h-[150px] lg:w-[150px] w-20 h-20 shrink-0'>
                                    <img className='lg:w-12 lg:h-12 w-6 h-6' src={Jeep} alt='jeep' />
                                    <div className='ucxH'>Automobile​</div>
                                </div>
                            </div>
                            <div data-aos="fade-right" className='relative lg:w-[150px] lg:h-[150px] w-[80px] h-80px] bg-white rounded-full flex items-center justify-center shrink-0'>
                                <div className='ucxCard flex flex-col space-y-2 lg:space-y-4 justify-center items-center mx-auto lg:h-[150px] lg:w-[150px] w-20 h-20 shrink-0'>
                                    <img className='lg:w-12 lg:h-12 w-6 h-6' src={Bank} alt='bank' />
                                    <div className='ucxH'>Banking</div>
                                </div>
                            </div>
                            <div data-aos="fade-right" className='relative lg:w-[150px] lg:h-[150px] w-[80px] h-80px] bg-white rounded-full flex items-center justify-center shrink-0'>
                                <div className='ucxCard flex flex-col space-y-2 lg:space-y-4 justify-center items-center mx-auto lg:h-[150px] lg:w-[150px] w-20 h-20 shrink-0'>
                                    <img className='lg:w-12 lg:h-12 w-6 h-6' src={Finance} alt='finance' />
                                    <div className='ucxH'>Finance</div>
                                </div>
                            </div>
                            <div data-aos="fade-right" className='relative lg:w-[150px] lg:h-[150px] w-[80px] h-80px] bg-white rounded-full flex items-center justify-center shrink-0'>
                                <div className='ucxCard flex flex-col space-y-2 lg:space-y-4 justify-center items-center mx-auto lg:h-[150px] lg:w-[150px] w-20 h-20 shrink-0'>
                                    <img className='lg:w-12 lg:h-12 w-6 h-6' src={Real} alt='real' />
                                    <div className='ucxH'>Real Estate</div>
                                </div>
                            </div>
                            <div data-aos="fade-right" className='relative lg:w-[150px] lg:h-[150px] w-[80px] h-80px] bg-white rounded-full flex items-center justify-center shrink-0'>
                                <div className='ucxCard flex flex-col space-y-2 lg:space-y-4 justify-center items-center mx-auto lg:h-[150px] lg:w-[150px] w-20 h-20 shrink-0'>
                                    <img className='lg:w-12 lg:h-12 w-6 h-6' src={Edu} alt='edu' />
                                    <div className='ucxH'>Education</div>
                                </div>
                            </div>
                            <div data-aos="fade-right" className='relative lg:w-[150px] lg:h-[150px] w-[80px] h-80px] bg-white rounded-full flex items-center justify-center shrink-0'>
                                <div className='ucxCard flex flex-col space-y-2 lg:space-y-4 justify-center items-center mx-auto lg:h-[150px] lg:w-[150px] w-20 h-20 shrink-0'>
                                    <img className='lg:w-12 lg:h-12 w-6 h-6' src={Gov} alt='government' />
                                    <div className='ucxH'>Government​</div>
                                </div>
                            </div>
                            <div data-aos="fade-right" className='relative lg:w-[150px] lg:h-[150px] w-[80px] h-80px] bg-white rounded-full flex items-center justify-center shrink-0'>
                                <div className='ucxCard flex flex-col space-y-2 lg:space-y-4 justify-center items-center mx-auto lg:h-[150px] lg:w-[150px] w-20 h-20 shrink-0'>
                                    <img className='lg:w-12 lg:h-12 w-6 h-6' src={Ecom} alt='ecommerce' />
                                    <div className='ucxH'>E-commerce​</div>
                                </div>
                            </div>
                            <div data-aos="fade-right" className='relative lg:w-[150px] lg:h-[150px] w-[80px] h-80px] bg-white rounded-full flex items-center justify-center shrink-0'>
                                <div className='ucxCard flex flex-col space-y-2 lg:space-y-4 justify-center items-center mx-auto lg:h-[150px] lg:w-[150px] w-20 h-20 shrink-0'>
                                    <img className='lg:w-12 lg:h-12 w-6 h-6' src={Fast} alt='fast' />
                                    <div className='ucxH'>Shipping & <br />logistics​​</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
                <div className='mt-14 lg:mt-[40px] mb-0'>
                    <div className='bg-[#f7f7f7] py-10'>
                        <div className='top_section flex flex-col justify-center items-center'>
                            {/* <h2 className='text-center text-[#006FFF]'>Improve your customer service with a<br /> strong, user-friendly ticketing solution.</h2> */}
                            <div className='pt-8 text-[#797979] text-base lg:text-lg font-semibold text-center'>Any inquiries will be promptly answered by our support team</div>
                            <Link to="../contact" className='mt-6 rounded text-white text-base font-semibold px-6 py-4 bg-[#006FFF] hover:bg-[#224ed4]'>Contact for More</Link>

                        </div>
                    </div>
                </div>
            </div>            
            <Footer />
        </div>
    )
}

export default Landing
