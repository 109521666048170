import ContactCenterImg from "../images/contactCenter.png";
import AIImg from "../images/aichat.png";
import CallAnalyticsImg from "../images/callanalytics.png";
import CallBackImg from "../images/callbackmanager.png";
import CobrowserImg from "../images/cobrowse.png";
import CrmImg from "../images/crm.png";
import DigitalWorkImg from "../images/digitalworkspace.png";
import OutBoundImg from "../images/outbounddialer.png";
import QualityImg from "../images/quality.png";
import SamrtRuleImg from "../images/smartrule.png";
import SmsImg from "../images/sms.png";
import SocialImg from "../images/social.png";
import SocialEngageImg from "../images/socialengage.png";
import SupportImg from "../images/support.png";
import VideoImg from "../images/videokyc.png";
import SmartSurvayImg from "../images/smartSurvay.png";
import VideoChatImg from "../images/icons/video.png";
import DrawImg from "../images/icons/draw.png";
import DocImg from "../images/icons/doc.png";
import FieldImg from "../images/icons/field.png";
import LogsImg from "../images/icons/logs.png";
import ThirdPartyImg from "../images/icons/third.png";
import EsignImg from "../images/icons/esign.png";
import OnOffImg from "../images/icons/onOff.png";
import DeviceImg from "../images/icons/device.png";
import CommentsImg from "../images/icons/comments.png";
import RefreshImg from "../images/icons/refresh.png";
import SocialMediaImg from "../images/icons/social.png";
import VoiceImg from "../images/icons/voice.png";
import InsightImg from "../images/icons/insight.png";
import IntegrationImg from "../images/icons/integration.png";
import InteractionImg from "../images/icons/interaction.png";
import TicketingWork from "../images/ticketingWorkflow.png";
import TicketingEase from "../images/ticketingEase.png";
import TicketingReport from "../images/ticketingReport.png";
import TicketingConversation from "../images/ticketingConversation.png";
import TicketingWindowImg from "../images/ticketingWindows.png";
import TicketingAgentWindowImg from "../images/ticketingAgentWindow.png";
import PowerBiImg from "../images/powerBi.jpg";


import OracleImg from "../images/partners/oracle.png";
import FreshdeskImg from "../images/partners/freshdesk.png";
import SugarCrmImg from "../images/partners/sugarcrm.png";
import ZohoImg from "../images/partners/zoho.png";
import DynamicsImg from "../images/partners/dynamics.png";
import SalesForceImg from "../images/partners/salesforce.png";
import ZendeskImg from "../images/partners/zendesk.png";

import CostBreakUpImg from "../images/icons/costbreakup.png";
import CalltrackingImg from "../images/icons/calltracking.png";
import InteractivedashImg from "../images/icons/interactivedash.png";
import AlertsImg from "../images/icons/alerts.png";
import FlexibilityImg from "../images/icons/flexibility.png";
import MultisiteImg from "../images/icons/multisite.png";
import CallreportsImg from "../images/icons/callreports.png";
import DatacontrolImg from "../images/icons/datacontrol.png";
import AdintegrationImg from "../images/icons/adintegration.png";
import onpremisesImg from "../images/icons/onpremises.png";

import SecurityImg from "../images/icons/security.png"
import futureImg from "../images/icons/future.png"
import protectionImg from "../images/icons/protection.png"
import onboardImg from "../images/icons/onboard.png"
 
export const products_card_data = [
  {
    image: ContactCenterImg,
    head: "Digital Contact Center",
    para: "A revolutionary platform where cutting-edge technology meets seamless communication. Experience the next level of customer support andinteraction, ensuring efficiency and satisfaction in the digital era.",
    link: "../digital-contact-center",
    docLink: "https://inaipiapp.com/files/features/InaipiDCC.pdf",
  },
  {
    image: AIImg,
    head: "AI Chatbot",
    para: "Engage your customers across digital platforms such as Website, Facebook Messenger, Twitter, or WhatsApp, with 24*7 automated personalized responses and if required provide chat escalation to agents.",
    link: "../ai-chatbot",
  },
  {
    image: SamrtRuleImg,
    head: "Smart- X",
    para: "A No-Code system that helps enterprises to host all the business rule in a centralized place and extend to any of the customer touchpoints seamlessly.",
    link: "../smart-x",
  },
  // {
  //   image: DigitalWorkImg,
  //   head: "Digital Agent Workspace",
  //   para: "A platform that provides customer service agents with a centralized hub to manage customer interactions across multiple channels.",
  //   link: "../digital-work-space",
  // },
  {
    image: CobrowserImg,
    head: "Co Browsing",
    para: "A powerful visual engagement tool that seamlessly shares screens during live support sessions, empowering agents to assist customers in real-time, and enhancing communication and problem-solving capabilities.",
    link: "../co-browsing",
  },
  // {
  //   image: VideoImg,
  //   head: "Video KYC",
  //   para: "Enables personalized interactions, fostering trust and loyalty by connecting with your customers face-to-face.",
  //   link:"../video-kyc"
  // },
  {
    image: SocialImg,
    head: "Social Media Connector",
    para: "Engage in two-way conversations and personalized interactions with customers directly. Chats/comments from WhatsApp and Social Media will be routed to the agent wherein the agent can respond/reply to those.​",
    link: "../social-media-connector",
  },

  {
    image: SupportImg,
    head: "Ticketing System",
    para: "Empower and transform your business workflow with a full-ﬂedged ticketing system with mini CRM. Allow agents to easily create a ticket and record important notes while on a call. Trigger real-time notiﬁcations or escalate directly to the concerned teams.​",
    link: "../ticketing-system",
  },
  {
    image: CrmImg,
    head: "CRM Connector",
    para: "Business applications like CRM/ERP, ticketing tools, etc. can be seamlessly integrated and boosts their ability with our connector. It activates a screen pop-up that pulls customer data and efficiently presents it to agents for enhanced customer service. Provides additional call control options to non-contact center users​",
    link: "../crm-connector",
  },
  {
    image: SmartSurvayImg,
    head: "Smart Survey",
    para: "Conducts automated post-interaction surveys via IVR, SMS, or email, to gain valuable insights and understand your customers' preferences, needs, and satisfaction levels.​​",
    link: "../smart-survey",
  },
  {
    image: CallBackImg,
    head: "Call Back Manager",
    para: "A versatile tool that recovers missed customer service opportunities by monitoring dropped calls, compiling a list, and automatically connecting customers with available agents.",
    link: "../call-back-manager",
  },
  {
    image: OutBoundImg,
    head: "Outbound Dialer",
    para: "System will take the data from the customer database to make automatic outbound calls. Once the called person answers the call, the pre-recorded announcement will be played to the user or connect the call to the available outbound agent.​",
    link: "../out-bound-dialer",
  },
  {
    image: CallAnalyticsImg,
    head: "Call Analytics",
    para: "Provides an overview of analysis, reporting and optimization capabilities for all your telephony activities with the help of an advanced Call Management System.",
    link: "../call-analytics",
  },
  {
    image: SmsImg,
    head: "SMS Solution",
    para: "Optimize your communication strategies effortlessly with our streamlined messaging solution. Whether it's sending individual messages or bulk notifications, our system seamlessly integrates with your CRM or backend systems.",
    link:"../sms-solutions"
  },
  {
    image: QualityImg,
    head: "Quality Monitoring Software",
    para: "Evaluate and enhance call center interactions seamlessly, monitoring conversations and pinpointing areas for improvement.",
    link: "../quality-monitoring",
  },
  {
    image: SocialEngageImg,
    head: "Social Media Engagement Platform",
    para: "Unify your customer experience and marketing with real-time social listening, analytics, and insights.​",
    link: "../socialmedia-engagement-platform",
  },
  {
    image: PowerBiImg,
    head: "PowerInsight Analytics",
    para: "Overcome your data inconsistency and extract meaningful insights and analytics to streamline your performance tracking.",
    link: "../power-bi-reports",
  },
];

export const ai_keyFeatures = [
  {
    head: "Omni-channel Engagement:",
    para: "Bring customer engagements across more than 30+ channels for seamless experiences.",
  },
  {
    head: "Multilingual Support: ",
    para: "Serve a global audience with multilingual support, breaking down language barriers and expanding your reach.",
  },
  {
    head: "Instant Customer Support: ",
    para: "Automated 24*7 customer assistance engaging customers with immediate answers to common queries.",
  },
  {
    head: "Seamless Handoff to Live Agent: ",
    para: "The chatbot recognizes when the user's inquiry or problem exceeds its capabilities or demands human expertise. It seamlessly transfers the user to a live agent, preserving the conversation context for the agent's review, ensuring customers avoid the need to reiterate their concerns.",
  },
  {
    head: "Effortless Integration:",
    para: "Seamlessly integrate into your website or app with just a few lines of code, making it easy to enhance your customer experience.",
  },
  {
    head: "Purpose-specific functionality: ",
    para: "Pre-trained, has chat widgets with FAQs and web portal to manage pre-defined Q&A.",
  },
  {
    head: "AI Learning : ",
    para: "The chatbot continuously learns and improves over time, ensuring that it becomes even more valuable to your business as it evolves.",
  },
  {
    head: "Scalable: ",
    para: "Our chatbot scales effortlessly to meet your needs whether you have a small business or a large enterprise.",
  },
];

export const ai_value_add = [
  {
    head: "Increase First-Call Resolution",
    para: "",
  },
  {
    head: "Foster Self-Service: ",
    para: "Enhance Customer Satisfaction with self-service automation",
  },
  {
    head: "Augment Customer Engagement:",
    para: "Personalized real-time conversation for customers engaging your business",
  },
  {
    head: "",
    para: "Resonate with the brand's customers",
  },
  {
    head: "Boost Agent Performance: ",
    para: "Enhance helpdesk efficiency with automated workflows and automated repeated queries.",
  },
  {
    head: "Increase Sales: ",
    para: "Boost your sales and conversions with proactive product recommendations and guided purchasing assistance from our AI chatbot.",
  },
  {
    head: "Lead Generation:",
    para: "Use the chatbot to capture leads and grow your customer base through engaging conversations and opt-in forms.",
  },
  {
    head: "Data-Driven Insights: ",
    para: "Gain insights into user behavior, marketing effectiveness, and customer satisfaction using AI to make data-driven smart decision goals.",
  },
  {
    head: "Cost-Efficient: ",
    para: "Reduce customer support costs by automating routine inquiries and tasks, freeing up your team to focus on more complex issues.",
  },
];

export const dcc_feature_list = [
  {
    head: "3 in 4",
    para: "Buy more from companies that make it easier to engage with them",
  },
  {
    head: "Only 21 %",
    para: "Rate the digital experiences of large multinational organizations as excellent",
  },
  {
    head: "80 %",
    para: "Expect organizations to use AI in their digital experiences for meaningful conversations",
  },
  {
    head: "48 %",
    para: "Say that brands work on silos on individual touchpoint leading to an unpleasant overall CX",
  },
  {
    head: "58 %",
    para: "Of consumers say convenience is more important than price",
  },
  {
    head: "8 in 10",
    para: "Customers evaluate brands based on their familiarity and comprehension",
  },
  {
    head: "41 %",
    para: "topped using multiple brands due to poor customer experience in the past year",
  },
  {
    head: "$ 136.8 B",
    para: "US Companies lose $ 136.8 billion/ year due to avoidable consumer switching",
  },
  {
    head: "53 %",
    para: "Reported high effort in seeking resolution during a crisis without prior interaction history",
  },
];

export const dcc_jouney_list = [
  {
    blueHead: "Pre-Interaction",
    subPara: [
      {
        boldHead: "AI Chatbot:",
        subPara:
          "Enhance customer interactions with AI-powered chatbots, providing instant, intelligent responses and support. These virtual assistants leverage natural language processing and machine learning to understand and address user queries, streamlining communication processes.",
      },
      {
        boldHead: "Self-Service Automation:",
        subPara:
          "Revolutionize customer service by implementing self-service automation. Enable users to independently find information, troubleshoot issues, and perform tasks through intuitive interfaces. This automation reduces dependency on human intervention, leading to faster resolutions and increased user satisfaction.",
      },
      {
        boldHead: "Social Media Connectors:",
        subPara:
          " Seamlessly integrate your customer support with various social media platforms. Social media connectors enable businesses to monitor and engage with customers across different channels. Enhance brand visibility, address concerns in real-time, and foster a responsive and dynamic online presence.",
      },
    ],
  },
  {
    blueHead: "Proactive Interaction",
    subPara: [
      {
        boldHead: "Call Back Manager:",
        subPara:
          " Optimize customer engagement by allowing users to request a return call at their convenience. This feature enhances customer satisfaction by reducing wait times and ensuring efficient communication, ultimately improving the overall customer experience.",
      },
      {
        boldHead: "SMS Solution:",
        subPara:
          " Elevate your messaging strategy with our SMS Solution. Utilize text messages to convey important information, promotions, or updates directly to your audience's mobile devices. Enhance communication efficiency and reach customers instantly through this versatile and widely used communication channel.",
      },
      {
        boldHead: "Outbound Dialer:",
        subPara:
          "Boost outbound communication efforts with an Outbound Dialer. This solution automates the process of making calls to customers, prospects, or stakeholders. Improve outreach effectiveness, streamline communication campaigns, and maximize the impact of your proactive engagement strategies.",
      },
    ],
  },
  {
    blueHead: "During Interaction",
    subPara: [
      {
        boldHead: "Digital Agent Workspace: ",
        subPara:
          "Transform your agent's working environment by providing a centralized platform for managing customer interactions. This enhances efficiency by consolidating information and tools, empowering agents to deliver seamless and personalized service.",
      },
      {
        boldHead: "Smart-X Rule Engine: ",
        subPara:
          "Optimize decision-making processes with a powerful tool that automates and enforces business rules. This engine leverages intelligent algorithms to enhance operational efficiency, ensuring that tasks and processes align with predefined criteria and business objectives.",
      },
      {
        boldHead: "Agent Assistant: ",
        subPara:
          " Empower your agents with an intelligent assistant designed to enhance productivity. This tool leverages artificial intelligence to provide real-time information, suggested responses, and relevant data, enabling agents to deliver prompt and accurate support.",
      },
      {
        boldHead: "Collaboration Tools:",
        subPara:
          "Foster teamwork and streamline communication with tools like co-browsing, video chat, e-signature, etc. These tools facilitate real-time interaction, document sharing, and collaboration, promoting efficient teamwork and knowledge sharing.",
      },
      {
        boldHead: "CRM Connector: ",
        subPara:
          " Integrate seamlessly with your CRM system ensuring a unified view of customer data, allowing agents to access and update information effortlessly, leading to more informed and personalized interactions.",
      },
      {
        boldHead: "Ticketing System:",
        subPara:
          "This solution enables efficient management of customer queries, issues, and requests by creating a structured system for logging, prioritizing, and resolving tickets, issue tracking and ensuring a streamlined customer support process.",
      },
    ],
  },
  {
    blueHead: "Post Interaction",
    subPara: [
      {
        boldHead: "Customer Smart Survey:",
        subPara:
          "Elevate your feedback collection process. This solution goes beyond traditional surveys, utilizing intelligent techniques to gather valuable insights from customers. Enhance your understanding of customer preferences and opinions, driving strategic decision-making.",
      },
      {
        boldHead: "Data Analytics & Reporting:",
        subPara:
          "Harness the power of data. Analyze key metrics, trends, and patterns to derive actionable insights. Empower your business to make informed decisions and continually improve operations based on a comprehensive understanding of performance data.",
      },
      {
        boldHead: "Social Media Engagement Platform: ",
        subPara:
          " This tool allows businesses to manage, monitor, and strengthen their social media presence, activities, customer engagements in real-time, and analyze social media performance. Enhance brand visibility and build meaningful connections across various platforms.",
      },
    ],
  },
];

export const digital_work_space_features = [
  {
    head: "1. Supports the below channels:",
    sub_head: [
      {
        inner_head: "a. CHAT ",
        // caption:"Below activities can be performed during the chat conversation:",
        // points:[
        //     "i.Co-Browse",
        //     "ii.Transfer to WhatsApp",
        //     "iii.Transfer to Agent ",
        //     "iv.Transfer to Supervisor",
        //     "v.Conference to Agent ",
        //     "vi.Conference to Supervisor",
        //     "vii.Wrap Up with Reason Codes ",
        //     "viii.Attach Files",
        //     "ix.Emojis",
        //     "x.Audio record",
        //     "xi.Canned messages",
        // ]
      },
      {
        inner_head: "b. VOICE",
        // caption:"Below activities can be performed during voice conversation:",
        // points:[
        //     "i.Transfer to WhatsApp ",
        //     "ii.Wrap Up with Reason Codes ",
        //     "iii.Hold call ",
        //     "iv.Transfer call ",
        //     "v.Conference call",
        // ]
      },
      {
        inner_head: "c. EMAIL",
        // caption:"Below activities can be performed during email conversation:",
        // points:[
        //     "i.Cannot Handle ",
        //     "ii.Escalation ",
        //     "iii.No Action Mail ",
        //     "iv.Wrap Up with reason Codes ",
        //     "v.Send Templates ",
        //     "vi.Attach file ",
        //     "vii.Attach image ",
        //     "viii.Reply ",
        //     "ix.Forward ",
        //     "x.Reply All",
        // ]
      },
      {
        inner_head: "d. SOCIAL MEDIA",
        // caption:"Customer can connect through below social media channels:",
        // points:[
        //     "WhatsApp",
        //     "Facebook",
        //     "Teams",
        // ]
      },
    ],
  },
  {
    head: "2. Customer History will be available in terms of all channels.",
    sub_head: [
      {
        inner_head: "",
        caption: "",
        // points:[
        //     "Interaction History ",
        //     "Smart-X",
        //     "Files",
        //     "Tickets",
        //     "Survey"
        // ],
        // below_caption:"Above features are same for both agent & supervisor."
      },
    ],
  },
  {
    head: "3. In Supervisor Portal, Agent & Power Bi reports are available ",
    // sub_head:[
    //     {
    //         inner_head:"a. Agent Reports: ",
    //         caption:"Provide detailed agent reports for each agent.",
    //       },
    //      {
    //         inner_head:"b. Power Bi Reports:",
    //         caption:"Below reports are visible: ",
    //         points:[
    //             " i.Interaction report",
    //             "ii.Interaction summary report ",
    //             "iii.Skillset report ",
    //             "iv.Month wise report ",
    //             "v.Agent performance & statistics ",
    //             "vi.Agent not ready report ",
    //             "vii.Overall dashboard ",
    //             "viii.Interaction dashboard ",
    //             "ix.Email dashboard ",
    //             "x.Voice dashboard",
    //         ],
    //       }
    // ]
  },
  {
    head: "4. Best routing of messages to the expert agent ensures efficient and prompt responses.",
  },
  {
    head: "5. Supports message formats, such as text, audio/video links, and emojis. ",
  },
  {
    head: "6. Voice calls, emails, and webchat are supported by Connector. ",
  },
  {
    head: "7. Efficient and timely responses from agents foster improved customer satisfaction. ",
  },
  {
    head: "8. Social media performance with detailed analytics, including engagement metrics, follower growth, etc are available. ",
  },
  {
    head: "9. Provide valuable insights into the customer's interactions with the Agent platform.",
  },
];

export const co_browser_key_feature = [
  {
    img: VideoChatImg,
    name: "Video chat",
  },
  {
    img: DrawImg,
    name: "Drawing tool",
  },
  {
    img: DocImg,
    name: "Document sharing",
  },
  {
    img: DrawImg,
    name: "Drawing tool",
  },
  {
    img: FieldImg,
    name: "Field masking",
  },
  {
    img: LogsImg,
    name: "Action logs",
  },
  {
    img: ThirdPartyImg,
    name: "Third - party content support",
  },
  {
    img: EsignImg,
    name: "E-Signing",
  },
  {
    img: OnOffImg,
    name: "Control switch",
  },
  {
    img: DeviceImg,
    name: "device & browser support",
  },
];

export const co_browser_highlights = [
  "Multi-browser support for real time web page interaction",
  "Collaborative browsing session initiated using voice, chat by agent or customer",
  "Cost efficient it works with the tools you already use",
  "Autonomous control managed by customer for introduction of agent support and information",
  "Support for hovering, highlight text, automatic rescind, window re-size, secure document fill-in",
  "Customer/Agent Real-Time Interface with multiple browsers",
  "No download necessary",
  "No code necessary",
  "Fully secure and complaint with leading industry standards",
  "Records all required interactions even third party websites",
  "Cloud-based SaaS solution",
  "Robust security and privacy options",
];

export const co_browser_needs_for = [
  {
    head: "Provide Real-Time Assistance: ",
    para: "The support agent can guide the customer through the issue by highlighting specific areas on the screen, making annotations, or sharing information in real time.",
  },
  {
    head: "Enhanced First Contact Resolution Rates: ",
    para: "Agents aid customers with a better resolution process and commute the time needed to resolve the issue.",
  },
  {
    head: "Continued Conversations from other Channels: ",
    para: "Co-browsing supports multi-channel integration that results in initiating the conversation in one channel and seamlessly transitioning to co-browsing for real-time customer support.Faster Resolution Process: With co-browsing, agents help customers better understand the resolution process better, reducing overall resolution time.",
  },
  {
    head: "Deliver Personalized and Secured Service: ",
    para: "Co-browsing enables personalized support by allowing the agents to recognize specific customer issues and provide targeted solutions enhancing customer satisfaction.",
  },
  {
    head: "Increase Customer Satisfaction: ",
    para: "It enables your sales and support teams to be with your customers every step of the way. It integrates with communication tools like live chat and video conferencing software to provide a seamless omnichannel experience.",
  },
];

export const smart_rule_list = [
  {
    head: "Why?",
    para: <p></p>,
    points: [
      "Customer engagement is made more efficient, personalized and memorable.",
      "Captures and utilizes customer data to enhance the experience and aid decision-making.",
      "Customers prefer quick, efficient interactions to address their needs.",
      "For enterprises, valuable customer interaction time provides better service, revenue, and satisfaction.",
      "Enables efficient interactions to meet both customer and enterprise goals.",
      "Help enterprises build great CX stories.",
    ],
  },
  {
    head: "How?",
    para: (
      <p>
        Every customer is unique, so businesses must understand their goals and
        deliver consistent, timely actions.
      </p>
    ),
    points:[
      "Designed to analyze unstructured data to create a unique agent and self-service experience.",
      "Predicts customer intent and personalizes interactions to boost customer satisfaction.",
      "Smart-X Flow Designer uses drag-and-drop UI to define flows that orchestrate and automate Contact Center platform components.",
      "Its flexible interaction builder connects data from multiple sources to decideNBA in any of the interactions.",
      "Its powerful rule builder creates complex rules for execution across all traditional and digital channel touchpoints.",     
    ]
  },
];

export const smart_rule_function_list = [
  {
    head: "RULE ENGINE: ",
    para: "A centralized rule builder to visually design and build business rules across all channels. Group multiple conditions using AND?OR clauses, nested business Rules",
  },
  {
    head: "DASHBOARD: ",
    para: "Single view to see all the business rules across channels, conditions at one place. Users can also view the outcome of the business rules executed in the dashboard",
  },
  {
    head: "ADMIN: ",
    para: "Multiple roles can be defined based on the user categories; this provides secure access to carry out ADD/edit/delete optons. Audit trail and maker / Checker feature help to manage and control changes in the system.",
  },
  {
    head: "SIMULATOR: ",
    para: "Simulate the defined rules by executing them to see the outcomes before Execute and see the rules outcome.",
  },
  {
    head: "INTERFACE: ",
    para: "API support for any external application to integrate with Rule Engine. The exposed APIs are Rest API that can be integrated with Any System",
  },
];

export const smart_rule_key_benifits = [
 "Empowers agents and supervisors to think beyond the customer",
 "Learn /analyze & enable the channel of choice for the customer",
 "Facilitates smart self-service",
 "Offers smart assistance to maximize CX investment",
 "Empowers business users to shape the customer journey",
 "Create flows with Smart-X Flow Designer to invoke the BRE",
];

export const smart_rule_use_cases = [
  {
    head:"Customer Segmentation and Personalization:",
    para:"Smart-X segments customers based on behavior, preferences, and interactions, allowing tailored marketing campaigns and services, boosting customer satisfaction and loyalty."
  },
  {
    head:"Dynamic Pricing in e-Commerce:",
    para:"Smart-X facilitates dynamic pricing in e-commerce, adjusting prices, launching promotions, and adapting campaigns based on real-time data and user behaviour."
  },
  {
    head:"Fraud Detection in Financial Transactions: ",
    para:"Implement rules that trigger alerts or restrict transactions based on unusual spending patterns, enhancing security measures."
  },
  {
    head:"Inventory Management in Retail: ",
    para:"Smart-X optimizes retail inventory by automating restocking for popular items, adjusting promotions based on stock levels, and enhancing overall inventory efficiency."
  },
  {
    head:"Healthcare Automation: ",
    para:"Set rules that automate processes like clinical guidelines, patient categorization, treatment planning, appointment scheduling, etc., improving operational efficiency and patient care."
  },
  {
    head:"Compliance Management:",
    para:"Set up rules that enforce compliance requirements, organizations can mitigate risks, avoid penalties, and maintain transparency in their operations, ensuring compliance with laws, regulations, and internal policies."
  }
];

export const smc_benifits = [
  "Increase Customer Reach",
  "Expand User Interaction Channel",
  "Improve Customer Experience",
  "Maximize Agent Performance",
  "Quick Agent Adoption",
  "Real-Time Customer Response",
  "Maintain a Consistent Social Media Presence",
];

export const smart_survey_cards = [
  {
    img: VoiceImg,
    name: "IVR",
  },
  {
    img: CommentsImg,
    name: "SMS",
  },
  {
    img: RefreshImg,
    name: "Email",
  },
  {
    img: SocialMediaImg,
    name: "Social media",
  },
];

export const smart_survey_feature = [
  {
    img: InteractionImg,
    head: "Capture Every Interaction",
    para: "With our survey platform, you'll never miss an opportunity to gather valuable feedback.",
    points: [
      "Post-call surveys",
      "Scripted survey over an ongoing call",
      "Survey link via SMS/Email/ Social Media Channels",
    ],
  },
  {
    img: IntegrationImg,
    head: "Open Integration ",
    para: "Seamlessly incorporate our survey into your existing systems and workflows, ensuring a hassle-free experience Integrate with:",
    points: [
      "External email server",
      "SMS provider",
      "Third party databases and applications",
    ],
  },
  {
    img: InsightImg,
    head: "Actionable Insights",
    para: "Don't just collect data, Transform feedback into improvements",
    points: [
      "Detailed statistics on customer satisfaction",
      "Interactive web dashboard",
      "Customizable survey templates",
      "NPS score calculation",
    ],
  },
];

export const smart_survey_working = [
  {
    head: "Increases Brand Reputation: ",
    para: "Smart surveys allow you to gather valuable feedback from customers and address their concerns promptly. By actively listening to your customers and demonstrating your commitment to improving their experience, you can enhance your brand's reputation and build trust.",
  },
  {
    head: "Know Your Customers: ",
    para: "Smart surveys provide deep insights into your customers' preferences, behaviors, and expectations. This knowledge enables you to tailor your products and services to meet their needs, ultimately improving customer satisfaction and loyalty.",
  },
  {
    head: "Retains Customers: ",
    para: "High-quality, personalized surveys help retain customers by making them feel valued. When customers see that you are willing to adapt and cater to their preferences, they are more likely to stay loyal to your brand.",
  },
  {
    head: "Multi-Channel Survey through various Touchpoints:  ",
    para: "This can be deployed across multiple channels, such as email, social media, and websites, ensuring that you reach customers where they are most active. This versatility helps you gather a comprehensive range of feedback.",
  },
  {
    head: "Provides Seamless Integration: ",
    para: "Integration capabilities with external email servers, SMS providers, and third-party databases and applications enabling efficient customer feedback collection and management, maximizing survey impact",
  },
  {
    head: "Enhances Customer Service: ",
    para: "With real-time feedback from smart surveys, you can identify and address issues as they arise. This proactive approach to customer service can significantly improve customer satisfaction and reduce the risk of negative reviews.",
  },
  {
    head: "Alert supervisor with unhappy customers: ",
    para: "Automatically flags responses from unhappy customers, allowing supervisors and support teams to intervene promptly. This enables you to resolve issues and prevent potential customer churn.",
  },
  {
    head: "Determine your CX Improvement Priorities: ",
    para: "By continuously monitoring feedback trends, smart surveys help you track changes in customer sentiment. This data allows you to prioritize areas for improvement and allocate resources accordingly, ultimately enhancing the customer experience.",
  },
  {
    head: "Easily calculates NPS: ",
    para: "Smart surveys simplify the calculation of key performance indicators, such as Net Promoter Score (NPS). NPS is a critical metric for measuring customer loyalty and gauging your company's growth potential.",
  },
  {
    head: "Holistic View: ",
    para: "Provides 360-degree view of your customer's journey. They capture data at various touchpoints, enabling you to understand the entire customer experience and identify opportunities for optimization.",
  },
];

export const tickting_cards = [
  {
    img: TicketingConversation,
    head: "Enhanced Customer Conversations",
    points: [
      "Prioritize customer requests",
      "Create, delegate & escalate tickets",
      "Bring context to every conversation",
      "Know your customer",
      "Repository of knowledge",
    ],
  },
  {
    img: TicketingWork,
    head: "Workflows",
    points: ["Optimized workflows", "Push notifications", "Delegation process"],
  },
  {
    img: TicketingEase,
    head: "Ease of Integration",
    points: [
      "UCaaS and CCaaS services",
      "Business apps",
      "Avaya platform",
      "Social Media channels & SMS",
    ],
  },
  {
    img: TicketingReport,
    head: "Reporting",
    points: [
      "Interactive dashboard",
      "Mini CRM and built-in tracking",
      "Analytics reporting",
    ],
  },
];

export const tickting_key_features = [
  {
    head: "More Control and Workflow Automation: ",
    para: "Provides organizations with greater control over their support processes. It automates repetitive tasks, ensuring that tickets are routed to the right agents, and specific actions are taken based on predefined rules, resulting in increased efficiency and reduced manual effort.",
  },
  {
    head: "Centralized Data Management: ",
    para: "Ensures all customer interactions and case details are easily accessible from a single platform. This simplifies information retrieval, enhances data accuracy, and allows for better reporting and analysis.",
  },
  {
    head: "Enhances Customer Service: ",
    para: "Enable agents to access customer information quickly, leading to more personalized and efficient interactions. This, in turn, boosts customer satisfaction and loyalty.",
  },
  {
    head: "Statistical Capabilities:  ",
    para: "Equipped with advanced reporting and analytics features, our ticketing system generates insightful statistics and reports, providing valuable insights into support team performance, customer trends, and areas that need improvement, ultimately aiding in informed decision-making.",
  },
  {
    head: "Flexible Solution with Mini CRM: ",
    para: "Our ticketing system includes mini CRM features that not only handle customer issues but also maintain a record of customer interactions, preferences, and history. This added flexibility helps in building stronger and lasting customer relationships.",
  },
  {
    head: "Trigger Real-time Notiﬁcations: ",
    para: "Instantly alert agents or supervisors when new tickets are created, ensuring that issues are addressed promptly, thereby improving response times and customer satisfaction.",
  },
];

export const tickting_knowledge_base = [
  "Agent-Friendly Interface",
  "Quick & Simple Search Platform",
  "Enhance Agent Performance",
  "Prompt Response to customers Queries",
  "Quality customer experience",
  "Customer Satisfaction",
];

export const ticketing_privilages = [
  {
    img: TicketingWindowImg,
    head: "Admin Privileges",
    points: [
      "Create new knowledge base, add new categories and groups within it",
      "Edit the category, group or description",
      "Upload the related docs in the pdf, csv, txt, image formats",
    ],
  },
  {
    img: TicketingAgentWindowImg,
    head: "Agent Privileges",
    points: [
      "Readily available answers related to customer queries without the need for scattered searches",
      "Retrieve, download and share the relevant docs with the customer in just a few clicks",
      "Provide comments for necessary updates or modifications",
      "Search by category and with keyword",
    ],
  },
];

export const crm_key_feature = [
  "Capture Call Duration",
  "Screen popup in CRM Interface",
  "Click-to-Dial from the CRM",
  "Captures and syncs business insights",
  "Automated Call Logging of inbound and outbound calls",
  "Call Annotation",
  "Toast pop-up notification with context",
  "Desktop notification",
  "Call Controls",
  "Open to support all CRM systems",
  "Integrates with any third-party application or database",
  "Seamless access to information",
  "Generate and customize reports",
  "Integrated desktop application",
];

export const crm_benifits = [
  {
    head: "Enhances Customer Service: ",
    para: "Provides all customer information on one screen and gives more power to your agents to serve your customers.",
  },
  {
    head: "Quick Agent Adoption: ",
    para: "This eliminates the need to learn and use multiple applications as in many contact centers.",
  },
  {
    head: "Maximize Value from your Investments: ",
    para: "Get the best outcomes from all your CRM and Contact Centre capabilities. Deliver faster ROI.",
  },
];

export const crm_partners = [
  ZendeskImg,
  SalesForceImg,
  SugarCrmImg,
  OracleImg,
  ZohoImg,
  FreshdeskImg,
  DynamicsImg,
];

export const call_back_list = [
  {
    blueHead: "Features",
    points: [
      "Constantly monitors for and tracks missed or dropped calls",
      "Automatically dials customers back when agents become available or scheduling criteria is met",
      "Effectively address spikes in call volumes",
      "Reduces the supervisor’s time to monitor the abandoned calls, pull reports and dial out the abandoned calls manually",
      "Optimize agent utilization",
      "Captures and presents all missed call information to agents",
      "Keep track of missed calls and single-click calls back to customers",
      "Can be used in combination with your IVR to offer customers the option of receiving a callback if hold times exceed predefined KPIs.",
    ],
  },
  {
    blueHead: "Solution Components",
    points: [
      "Agent Status:  The system detects agent status, if they are ready.",
      "Automated Call Assignment: Once the status is Ready, the call is automatically assigned and dialed upon acceptance.",
      "User Action: After concluding the call, there is an option to close it with an appropriate disposition code, such as No answer,Hung-Up, or Complete. Additionally, agents can add remarks in the notes box.",
      "Auto Re-Dial: The system initiates a redial every 30 minutes until the call is completed, with the option to pre-define the number of attempts.",
      "Data Capture: Missed call data is automatically captured, eliminating the need for manual reporting.",
      "Reporting: Reports can be generated to assess the status of missed/abandoned calls. Also, there is a one-click dial option available for initiating manual calls.",
      "Call back Request: Customers who prefer not to wait in the IVR queue have the option to request a callback within the IVR system.",
    ],
  },
  {
    blueHead: "Benefits:",
    points: [
      "Manual Callback",
      "An agent can initiate call-backs using a web interface, equipped with a click-to-call button next to each missed contact.",
      "Call back web portal is integrated with Contact Centre to fetch the details of abandoned calls.",
      "Reports for the callback list can be downloaded in CSV, PDF, and Excel formats.",
      "Agent have an option to search specific abandoned calls based on called time/calling number",
      "Increases Productivity and achieve optimized performances",
    ],
  },
];

export const call_analyutics_highlights = [
  {
    img: CostBreakUpImg,
    name: "Cost Breakdown",
  },
  {
    img: CalltrackingImg,
    name: "call tracking",
  },
  {
    img: InteractivedashImg,
    name: "Interactive dashboard",
  },
  {
    img: AlertsImg,
    name: "Alerts",
  },
  {
    img: FlexibilityImg,
    name: "Flexibility",
  },
  {
    img: MultisiteImg,
    name: "Multi-Site support",
  },
  {
    img: CallreportsImg,
    name: "Reports",
  },
  {
    img: DatacontrolImg,
    name: "data Control",
  },
  {
    img: AdintegrationImg,
    name: "Single Sign On / AD Integration",
  },
  {
    img: onpremisesImg,
    name: "On-premise / Cloud Offerings",
  },
];

export const call_analyutics_call_billing = [
  "Web-based solution",
  "Phone call tracking/usage monitoring and reporting for Avaya PBX systems.",
  "Pre-defined reports for Incoming, Outgoing and Missed calls based on different filter options",
  "Updating ISP’s tariff",
  "Instant quick search for inbound/outbound call activities",
  "Trunk based reports",
  "Track emergency calls",
  "Multiple branch offices can be integrated to a centralized Call Billing server and managed as a single system",
  "Multi-tariff support",
  "Email Alerts and reports can be sent to user’s email",
];

export const call_analyutics_call_budgeting = [
  "Email alert will be sent to user upon reaching the allocated call cost limit",
  "Block extension from making external PSTN call once the allocated cost budget limit is reached",
];

export const social_engage_list = [
  {
    blueHead: "Highlights:",
    subPara: [
      {
        boldHead: "Centralized Social Media Management: ",
        subPara:
          "Centralizes all your social media accounts, providing a single dashboard for managing posts, interactions, and analytics.",
      },
      {
        boldHead:"Multi-Platform Compatibility: ",
        subPara:"Supports major social media platforms, including Facebook, Twitter, Instagram, and more, ensuring a wide reach for your social media efforts."
      },
      {
        boldHead:"Real-Time Response: ",
        subPara:"Respond to messages, and mentions in real-time, fostering meaningful interactions with customer. Offers a co-browsing feature that allows agents and customers to browse the same webpage together."
      },
       {
        boldHead:"In-Depth Analytics: ",
        subPara:"Provides detailed analytics and insights, allowing you to analyze engagement metrics, follower growth track the performance of your campaigns and make data-driven decisions."
      },
    ],
  },
  {
    blueHead: "Proactive Interaction",
    subPara: [
      {
        boldHead: "Multi-Platform Integration: ",
        subPara:"Centralize your social media accounts for streamlined management, saving time and effort through seamless integration and efficient coordination across multiple platforms."
       },
       {
        boldHead: "Social Listening: ",
        subPara:"Keyword-based listening to analyze and manage your workflow."
       },
       {
        boldHead: "Seamless Management: ",
        subPara:"Streamline your social media tasks, making it easy to schedule posts, monitor analytics, and engage with your audience effortlessly."
       },
       {
        boldHead: "Content Optimization: ",
        subPara:"Ensure your posts are visually appealing, on-brand, and tailored to resonate with your target audience."
       },{
        boldHead: "Campaign Management: ",
        subPara:"Plan, execute, and analyze the impact of your campaigns, maximizing their effectiveness."
       },{
        boldHead: "Real-Time Interaction: ",
        subPara:"Respond promptly to comments, messages, and mentions, ensuring your brand remains actively engaged and responsive."
       },{
        boldHead: "Analytics Insights: ",
        subPara:"Gain valuable insights into your social media performances like tracking key metrics, understanding your audience better, and refining your strategy for optimal results."
       },{
        boldHead: "Community Building: ",
        subPara:"Cultivate a thriving digital community, encourage discussions, run polls, and create a sense of belonging that keeps your audience coming back for more."
       },{
        boldHead: "Artificial Intelligence & Machine Learning: ",
        subPara:"Override the NLP logic by setting up keywords to mark sentiments."
       },{
        boldHead: "Advanced BI Tools: ",
        subPara:"Get real-time and customized reports and track customer sentiments."
       },
       {
        boldHead: "",
        subPara:"Whether you're a small business, influencer, or a large corporation, Social Media Engagement Platform is your go-to solution for enhancing social media engagements and achieving impactful results."
       }
    ],
  } 
];

export const sms_list = [
  {
    blue_head:"Features",
    para:"",
    points:[
      "Web-based system",
      "Send SMS instantly to multiple customers using the application.",
      "Keep track of sent SMS, recipient, time stamp, and keywords.",
      "Personalize bulk messages & pre-schedule SMSs",
      "Retain data and context for a seamless experience.",
      "Enables Integration with third-party databases & applications",
      "Enables Integration with Avaya Contact Center solution (IPO or Aura-based)",
    ]
  },
  {
    blue_head:"Functional Capabilities",
    para:"It provides a computer-based system, wherein individualized and bulk messages can be sent to mobile devices. All you need to do is to select the recipient or group, type the message and the message gets sent immediately.",
    points:[
      "User/Admin/Reporting Interface",
      "Contact Management",
      "Phonebook support",
      "Database lookup",
      "Contact import from Excel, CSV or text file",
      "Contact import from external DB or via web services",
      "Sender ID support",
      "Personalized Bulk Messages",
      "Scheduling the SMS",
      "Integration with third-party applications and Database",
      "Reporting",
     ]
  }
]

export const video_kyc_cards = [
  {
    img:SecurityImg,
    head:"Improved Security ",
    para:"Ownership of documents is verified live on video using scanned documents or images",
  },
  {
    img: InteractionImg,
    head:"Real-time processing and faster approval ",
    para:"Fully digital, no paper involved. Customers can get verified quickly.",
  },
  {
    img: protectionImg,
    head:"Multiple layers of protection",
    para:"Documents are auto-scanned, recorded, and validated with face recognition, with a human making final security decisions.",
  },
  {
    img: futureImg,
    head:"Future-proof  ",
    para:"Unlike physical bank branch ID verifications, the process is securely recorded, enabling future video access.",
  },
  {
    img: onboardImg,
    head:"Easily fits into current onboarding flow ",
    para:"to make sure users don’t drop out of the process.",
  },
]

export const out_bound_feaures =[
  "Create new service by importing a text file/ access table/ SQL table",
  "Modify database server",
  "Real-time monitoring and maintaining the services",
  "Reschedule services",
  "Assign priority to the Campaign and dialing numbers",
  "Dynamic changing of priority to both the Campaign and the dialing numbers",
  "Enable and disable campaigns dynamically",
  "Add records to the existing Campaign",
  "Admin can create and manage SMS templates for different scenarios",
  "Provide summary and detailed reports on outbound calls, agent calls, agent login and break, call transfer details, etc."
]

export const out_bound_benifits =[
   {
    head:"Improves Agent Efficiency: ",
    para:"Agents can save time by eliminating the need to manually dial customers, as the dialer efficiently determines optimal call times, resulting in a significant reduction in idle time."
   },
   {
    head:"Intelligent Call Assignment: ",
    para:"The call will be connected to the available outbound agent. Further, the predictive dialers will predict when a current call ends while already dialing the next number."
   },
   {
    head:"Reduce Operation Cost: ",
    para:"Automated outbound dialing process will enhance your productivity with less workforce."
   },
]


export const power_bi_importance = [
  {
    blueHead: "What’s the Importance? ",
    subPara: [
      {
        boldHead: "Real-Time Insights: ",
        subPara:
          "A contact center dashboard allows you to see live data on metrics such as Average Handle Time (AHT), Customer Satisfaction (CSAT), and First Call Resolution (FCR). No more waiting for end-of-day reports!",
      },
      {
        boldHead: "Performance Tracking: ",
        subPara:"You can track call center agent performance goals and productivity metrics seamlessly. The dashboard helps you monitor progress and pinpoint areas needing attention.",
      },
      {
        boldHead: "Quick Decision Making: ",
        subPara:"When you have all the critical data, you can make informed decisions quickly. Your dashboard will highlight a sudden spike in call abandonment rates, allowing you to take immediate action.",
      },
      {
        boldHead: "Customization: ",
        subPara:"Tailor your dashboard to display your team's most relevant call center metrics. These could include anything from schedule adherence to the success of new initiatives.",
      },
    ],
  },

  {
    blueHead: "Call Center Agent Performance Metrics for Maximum Value:",
    subPara: [
      {
        boldHead: "1.	Average Handle Time (AHT): ",
        subPara:"Measures efficiency by tracking call duration.",
      },
      {
        boldHead: "2.	First Call Resolution (FCR): ",
        subPara:"Indicates effective agent performance and customer satisfaction.",
      },
      {
        boldHead: "4.	Call Abandonment Rate: ",
        subPara:"This shows the percentage of callers who hang up before speaking to an agent.",
      },
      {
        boldHead: "5.	Service Level:",
        subPara:" Measures the percentage of calls answered within a specific time frame.",
      },
      {
        boldHead: "6.	Agent Utilization: ",
        subPara:"Tracks effective use of agents to balance workload.",
      },
    ],
  },
  
];

export const power_bi_key_features = [
  {
    head: "Real-Time Insights: ",
    para: "Dashboards provide real-time data on metrics like Average Handle Time (AHT) and First Call Resolution (FCR), helping you make quick, informed decisions to boost productivity.",
  },
  {
    head: "Enhanced Performance Tracking:",
    para: "You can easily monitor agent performance metrics using an Excel call center agent performance scorecard or a custom dashboard. "
  },
  {
    head: "Increased Accountability: ",
    para: "A transparent scorecard for call center agents ensures everyone knows what’s expected. It motivates agents to meet their targets and improves overall agent performance.",
  },
  {
    head: "Improved Productivity: ",
    para: "Dashboards streamline the monitoring process, allowing managers to focus on coaching and development. This leads to higher call center productivity and more efficient operations.",
  },
];