import React, { useState, useEffect, useRef } from "react";
import Topbar from "../bars/TopNav.js";
import ImgContact from "../image/ImgContact.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import Success from "./Success.js";
import Footer from "../bars/Footer.js";
import GoogleAnalytics from './GoogleAnalytics';
import Presense from "./products/components/Presense.js";
function Contact() {
  const [countryCode, setCountryCode] = useState("91");
  const [number, setNumber] = useState();
  const [phone, setPhone] = useState();
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [comments, setComments] = useState("");
  const [loading, setLoading] = useState(false);
  const [serverError, setError] = useState(false);
  const [msgSent, setMsgSent] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const API_URL =
    "https://1vdi4lgmfa.execute-api.us-east-1.amazonaws.com/insert_details";
  const codeHandle = (e) => {
    setCountryCode(e);
  };

  const numberHandle = (e) => {
    console.log("+" + countryCode + " " + e.target.value);
    setNumber(e.target.value);
    setPhone("+" + countryCode + " " + e.target.value);
  };

  const firstNamehandle = (e) => {
    setFirstName(e.target.value);
  };

  const secondNamehandle = (e) => {
    setSecondName(e.target.value);
  };

  const companyHandle = (e) => {
    setCompany(e.target.value);
  };

  const emailHandle = (e) => {
    setEmail(e.target.value);
  };

  const commentHandle = (e) => {
    setComments(e.target.value);
  };

  const formSubmit = async (e) => {
    e.preventDefault();

    var commentValue ;
    if(comments == ''){
      commentValue = "no comment";
    }
    else {
      commentValue = comments;
    }

    const body = {
      firstname: firstName,
      lastname: secondName,
      company:   company ,
      phone: phone,
      email: email,
      comments: commentValue,
    };

    try {
      setLoading(true);
      const response = await axios.post(API_URL, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(response);
      if (response.data.success) {
        window.scrollTo(0, 0);
        setNumber("");
        formRef.current.reset();
        setMsgSent(true);
        setTimeout(() => {
          setMsgSent(false);
        }, 5000);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      setError(true);
    }
  };

  const SuccessClose = () => {
    setMsgSent(false);
  };

  // useEffect(() => {
  //    window.dataLayer = window.dataLayer || [];
  //   function gtag() {
  //     window.dataLayer.push(arguments);
  //   }
  //   gtag('js', new Date());
  //   gtag('config', 'G-X0DL1BJQL6');
  // }, []);

  return (
    <div>
      <Topbar />
      <GoogleAnalytics />
      <div className="top_section mt-[120px] mb-[73px]">
        {!msgSent == true ? (
          <div className="flex lg:flex-row lg:space-x-20 flex-col bg-[#EFF3FF] lg:px-12 lg:py-14 p-6 rounded-2xl">
            <div className="flex-col lg:text-left text-center">
              <h2 className="lg:font-semibold font-medium">How can we help?</h2>
              <p className="w-full sm:w-[60%] lg:w-full lg:flex mx-auto lg:mx-0 mt-10 text-[#797979] lg:text-left text-center">
                We are here to listen, assist, and provide the support you need.
                We look forward to helping you and ensuring your experience with
                us exceeds your expectations.
              </p>
              <img
                className="contactImg mx-auto mt-[56px] lg:w-[333px] lg:h-[333px] shrink-0"
                src={ImgContact}
                alt="contact_us"
              />
            </div>
            <form
              ref={formRef}
              onSubmit={formSubmit}
              className="form space-y-2 lg:mt-0 mt-10"
            >
              <div className="flex sm:flex-row flex-col sm:space-x-5 space-y-4 sm:space-y-0">
                <div className="flex flex-col space-y-2">
                  <label className="label">First name</label>
                  {msgSent && <div>hii</div>}
                  <input
                    required
                    onChange={firstNamehandle}
                    placeholder="Your first name"
                    className="form-field"
                  />
                </div>
                <div className="flex flex-col space-y-2">
                  <label className="label">Last name</label>
                  <input
                    required
                    onChange={secondNamehandle}
                    placeholder="Your last name"
                    className="form-field"
                  />
                </div>
              </div>
              <div className="flex flex-col space-y-2">
                <label className="label">Company</label>
                <input
                  onChange={companyHandle}
                  placeholder="company name"
                  className="form-field"
                />
              </div>
              <div className="flex flex-col space-y-2">
                <label className="label">Business email</label>
                <input
                  required
                  onChange={emailHandle}
                  placeholder="example@gmail.com"
                  className="form-field"
                />
              </div>
              <div className="flex flex-col space-y-2">
                <label className="label">Phone number</label>
                <div className="flex space-x-6">
                  <PhoneInput
                    inputProps={
                      {
                        // disabled: true,
                      }
                    }
                    className="form-field"
                    style={{ width: "30%" }}
                    country={"in"}
                    value={countryCode}
                    onChange={codeHandle}
                  />
                  <input
                    required
                    value={number}
                    onChange={numberHandle}
                    type="text"
                    className="form-field w-full"
                  />
                </div>
              </div>
              <div className="flex flex-col space-y-2">
                <label className="label">Anything else? (Optional)</label>
                <textarea
                  onChange={commentHandle}
                  placeholder="What changes are looking for?"
                  className="form-field h-[72px] placeholder:pt-2"
                />
              </div>
              <button
                disabled={loading}
                className="sub-btn py-[18px] relative"
                type="submit"
              >
                Submit
                <div className="absolute top-1/2  -translate-y-1/2 right-1/3">
                  {loading && <div className="custom-loader"></div>}
                </div>
              </button>
              {serverError && <div className="serverError">Sorry something went wrong...</div>}
            </form>
          </div>
        ) : (
          <Success closeBtn={SuccessClose} />
        )}
        <Presense/>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
