import React, { useEffect } from "react";
import Topbar from "../bars/TopNav.js";
import About1 from "../image/About1.png";
import About11 from "../image/About1.1.png";
import About2 from "../image/About2.png";
import About3 from "../image/About3.png";
import About4 from "../image/About4.png";
import work from "../image/Work.png";
import cust from "../image/Customer.png";
import light from "../image/Lightbulb.png";
import pattern from "../image/Pattern.png";
import help from "../image/Help desk.png";
import Footer from "../bars/Footer.js";
import Presense from "./products/components/Presense.js";
// import { Navigation, Pagination, A11y } from 'swiper/modules';

// import { Swiper, div } from 'swiper/react';

// Import Swiper styles
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';

function About() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Topbar />
            <div className="lg:mt-[120px] mt-[70px] mb-[73px] about-us-page">
                <div className="relative">
                    {/* <div className="top_section">
            <img src={About1} className="lg:w-[90%] h-[480px] about1" />
            <img src={About1} className="w-full about11" />
          </div> */}
                    {/* <div className="aboutFlex bgAbout top-[65%] right-56 left-80"></div>
          <div className="aboutFlex mx-auto bg-white -bottom-44 right-0 lg:right-40 lg:left-96 rounded-lg border border-[#9E9E9E] p-10">
            <h2>About us</h2>
            <p className="md:pt-10 pt-5">
              Welcome to Inaipi digital UCX, a leading provider of innovative
              and customer-centric solutions that empower businesses to thrive
              in today's dynamic market. Our passion for excellence drives us to
              deliver cutting-edge solutions and services designed to elevate
              your organization's success. We have a team of dedicated
              professionals with diverse expertise in technology, customer
              service, and business strategy to revolutionize the way businesses
              interact with their customers and unlock their full potential. 
            </p>
          </div> */}
                    {/* <div className="flexAbout bgAbout top-[65%] left-0 right-0"></div>
          <div className="flexAbout mx-auto bg-white top-[80%] right-6 left-6 rounded-lg border border-[#9E9E9E] p-10">
            <h2>About us</h2>
            <p className="md:pt-10 pt-5">
              Welcome to Inaipi digital UCX, a leading provider of innovative
              and customer-centric solutions that empower businesses to thrive
              in today's dynamic market. Our passion for excellence drives us to
              deliver cutting-edge solutions and services designed to elevate
              your organization's success. We have a team of dedicated
              professionals with diverse expertise in technology, customer
              service, and business strategy to revolutionize the way businesses
              interact with their customers and unlock their full potential. 
            </p>
          </div> */}
                    <div className="top_section">
                        <div>
                            <div className="lg:hidden flex absolute top-24 left-0 w-full h-[400px] aboutus-bg-blue z-10"></div>
                            <div className="lg:hidden block absolute top-32 left-1/2 -translate-x-1/2 z-50 w-11/12 bg-white rounded-lg border border-[#9E9E9E] lg:p-10 p-5">
                                <h2>About us</h2>
                                <p className="pt-3 aboutus-para">
                                    Welcome to Inaipi, a leading provider of
                                    innovative and customer-centric solutions that empower
                                    businesses to thrive in today's dynamic market. Our passion
                                    for excellence drives us to deliver cutting-edge solutions and
                                    services designed to elevate your organization's success. We
                                    have a team of dedicated professionals with diverse expertise
                                    in technology, customer service, and business strategy to
                                    revolutionize the way businesses interact with their customers
                                    and unlock their full potential.
                                </p>
                            </div>
                        </div>
                        <div className="w-full relative">
                            <div className="lg:w-11/12">
                                <img src={About1} alt="about" className=" " />
                            </div>
                            <div className="absolute flex justify-end bottom-[-150px] right-0">
                                <div className="lg:flex hidden absolute bottom-10 right-10 w-10/12 h-full aboutus-bg-blue"></div>

                                <div className="lg:block hidden lg:w-5/6 w-full relative z-10 bg-white rounded-lg border border-[#9E9E9E] p-10">
                                    <h2>About us</h2>
                                    <p className="pt-3 aboutus-para">
                                        Welcome to Inaipi, a leading provider of
                                        innovative and customer-centric solutions that empower
                                        businesses to thrive in today's dynamic market. Our passion
                                        for excellence drives us to deliver cutting-edge solutions
                                        and services designed to elevate your organization's
                                        success. We have a team of dedicated professionals with
                                        diverse expertise in technology, customer service, and
                                        business strategy to revolutionize the way businesses
                                        interact with their customers and unlock their full
                                        potential.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="top_section our-vision-3 mt-[100px] sm:mt-[300px] lg:mt-[230px] md:mt-[90px] flex lg:flex-row flex-col lg:space-x-6 items-center">
                    <div className="flex flex-col  text-center lg:text-left">
                        <h2 className="text-left">Our Vision</h2>
                        <p className="md:pt-10 pt-5 md:w-[70%] w-full lg:w-full text-[#6A6A6A] text-left">
                            To create a seamless and personalized experience for customers
                            across all channels. We aim to leverage technology and innovation
                            to connect with customers in a meaningful way and to exceed their
                            expectations at every touchpoint. Our goal is to be the preferred
                            destination for customers seeking convenience, quality, and
                            exceptional service.
                        </p>
                    </div>
                    <img src={About2} alt="aboutImg" className="aboutImg mt-8 lg:mt-0" />
                </div>
                <div className="top_section mt-[100px] flex lg:flex-row flex-col-reverse lg:space-x-6 items-center">
                    <img src={About3} alt="aboutImg" className="aboutImg mt-8 lg:mt-0" />
                    <div className="flex flex-col  text-center lg:text-left">
                        <h2 className="text-left">Our Mission</h2>
                        <p className="md:pt-10 pt-5 md:w-[70%] w-full lg:w-full mx-auto lg:mx-0 text-[#6A6A6A] text-left">
                            To provide a unified and effortless experience for customers
                            through seamless integration of all channels. We strive to offer a
                            diverse range of products, exceptional customer service, and
                            innovative solutions that exceed customer expectations. We are
                            committed to delivering value and convenience to our customers
                            while driving sustainable growth for our business.
                        </p>
                    </div>
                </div>
                <div className="top_section lg:mt-[160px] mt-[80px]">
                    <h2 className="text-center">Why Choose Us? </h2>
                    <div className="w-full lg:mt-20 mt-8 grid grid-flow-row lg:grid-cols-3 grid-cols-1 gap-6">
                        <div className="card aboutCard flex flex-col text-left shrink-0">
                            <div className='relative lg:w-[64px] lg:h-[64px] w-[52px] h-[52px] bg-white rounded-full flex items-center justify-center shrink-0'>
                                <img src={work} alt="aboutImg" className="lg:w-8 lg:h-8 w-[26.667px] h-[26.667px]" />
                            </div>
                            <div className="cardH">Expertise</div>
                            <div className="cardP">
                                Our team consists of industry experts who bring years of
                                experience to provide the best-in-class solutions that align
                                with your business goals.
                            </div>
                        </div>
                        <div className=" card aboutCard flex flex-col shrink-0">
                            <div className='relative lg:w-[64px] lg:h-[64px] w-[52px] h-[52px] bg-white rounded-full flex items-center justify-center shrink-0'>
                                <img src={cust} alt="aboutImg" className="lg:w-8 lg:h-8 w-[26.667px] h-[26.667px]" />
                            </div>
                            <div className="cardH">Customer-Centric Approach</div>
                            <div className="cardP">
                                Your success is our priority. We take the time to understand
                                your specific requirements, ensuring that our solutions meet and
                                exceed your expectations.
                            </div>
                        </div>

                        <div className="aboutCard flex flex-col shrink-0">
                            <div className='relative lg:w-[64px] lg:h-[64px] w-[52px] h-[52px] bg-white rounded-full flex items-center justify-center shrink-0'>
                                <img src={light} alt="aboutImg" className="lg:w-8 lg:h-8 w-[26.667px] h-[26.667px]" />
                            </div>
                            <div className="cardH">Innovation</div>
                            <div className="cardP">
                                We stay ahead of the curve by embracing the latest technologies
                                and trends. Our innovative products and services keep you at the
                                forefront of your industry.
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 lg:mx-40 grid grid-flow-row lg:grid-cols-2 grid-cols-1 gap-6">
                        <div className="aboutCard flex flex-col shrink-0">
                            <div className='relative lg:w-[64px] lg:h-[64px] w-[52px] h-[52px] bg-white rounded-full flex items-center justify-center shrink-0'>
                                <img src={pattern} alt="pattern" className="lg:w-8 lg:h-8 w-[26.667px] h-[26.667px]" />
                            </div>
                            <div className="cardH">Seamless Integration</div>
                            <div className="cardP">
                                Implementing new systems can be daunting, but with Inaipi, the
                                process is smooth and hassle-free. Our team ensures seamless
                                integration and minimal disruptions to your operations.
                            </div>
                        </div>
                        <div className="aboutCard flex flex-col shrink-0">
                            <div className='relative lg:w-[64px] lg:h-[64px] w-[52px] h-[52px] bg-white rounded-full flex items-center justify-center shrink-0'>
                                <img src={help} alt="help" className="lg:w-8 lg:h-8 w-[26.667px] h-[26.667px]" />
                            </div>
                            <div className="cardH">Ongoing Support</div>
                            <div className="cardP">
                                Our commitment to your success extends beyond implementation. We offer continuous support and updates to ensure your business stays agile and competitive.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:mt-[160px] mt-[80px] top_section">
                    <div className="aboutCount">
                        <div className="grid grid-flow-row lg:grid-cols-3  grid-cols-1 gap-20 place-content-center place-items-center">
                            <div className="relative flex justify-center items-center">
                                <svg className="mb-8" xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 120 120" fill="none">
                                    <circle cx="60" cy="60" r="60" fill="#D2FFC7" />
                                </svg>

                                <div className="absolute flex flex-col space-y-3">
                                    <div className="aboutNo">1000+</div>
                                    <div className="aboutRt">Customers Served</div>
                                </div>
                                <div className="aboutLine relative lg:left-24 lg:bottom-0 right-14 -bottom-24 lg:rotate-0 rotate-90"></div>
                            </div>
                            <div className="relative flex justify-center items-center">
                                <svg className="mb-8" xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 120 120" fill="none">
                                    <circle cx="60" cy="60" r="60" fill="#FFFACD" />
                                </svg>
                                <div className="absolute flex flex-col space-y-3">
                                    <div className="aboutNo">2000+</div>
                                    <div className="aboutRt">Active Users</div>
                                </div>
                                <div className="aboutLine relative w-1 lg:left-28 lg:bottom-0 right-14 -bottom-24 lg:rotate-0 rotate-90 shrink-0"></div>
                            </div>
                            <div className="relative flex justify-center items-center">
                                <svg className="mb-8" xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 120 120" fill="none">
                                    <circle cx="60" cy="60" r="60" fill="#EBD8FF" />
                                </svg>
                                <div className="absolute flex flex-col space-y-3">
                                    <div className="aboutNo">750+</div>
                                    <div className="aboutRt">Deployed CC</div>
                                </div>
                            </div>
                        </div>
                        <a href="#/product" className="hover:underline underline-offset-4 decoration-1">
                            <p className="mt-10 text-center font-medium">
                                Explore our range of solutions and discover why numerous companies
                                have<br></br> chosen Inaipi to elevate their business and customer
                                relationships.
                            </p>
                        </a>
                    </div>
                </div>
                <div className="mt-[65px] top_section flex lg:flex-row flex-col space-x-6 items-center">
                    <div className="flex flex-col">
                        <h2 className="text-center lg:text-left">Life at Inaipi</h2>
                        <p className="md:pt-10 pt-5 text-[#6A6A6A] text-center lg:text-left">
                            At Inaipi, we don't just work; we create a vibrant community that
                            values every individual's unique contributions and aspirations.
                            Every day brings new opportunities to learn, grow, and make a
                            difference. Together, we build a fulfilling and rewarding life at
                            our company, embracing challenges with enthusiasm and cultivating
                            an environment where everyone thrives like a family.
                        </p>
                        <a href="#/career" className="pt-8 text-[#006FFF] text-[24px] font-medium text-center lg:text-left hover:underline underline-offset-8 decoration-[#006FFF] decoration-1">
                            Join us on this incredible journey
                        </a>
                    </div>
                    <img src={About4} alt="About4" className="aboutImg lg:mt-0 mt-5" />
                </div>
                <Presense/>
            </div>
            <Footer />
        </div>
    );
}

export default About;
