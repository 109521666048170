export const currentYear = new Date().getFullYear();
export const contactUsAPI =
  "https://1vdi4lgmfa.execute-api.us-east-1.amazonaws.com/insert_details";
export const customStyles = {
  headCells: {
    style: {
      backgroundColor: "#006fff",
      color: "#fff",
    },
  },
  cells: {
    style: {
      backgroundColor: "#fff",
      color: "#000",
    },
  },
  cells: {
    style: {
      backgroundColor: "#fff",
      color: "#000",
    },
  },
  rows: {
    style: { paddingTop: "10px", paddingBottom: "10px" },
  },
};

export const faqContent = [
  {
    question: "What is Inaipi DCC?",
    answer:
      "Inaipi Digital Contact Center is an omnichannel platform empowering agents to deliver seamless customer experience across all touchpoints. This innovative platform leverages AI-powered solutions to revolutionize the customer journey.",
    divName: "inaipiDcc",
  },
  {
    question: "What is Cloud Contact Center ?",
    answer:
      "A cloud contact center is a customer service and support solution that is hosted and operated in the cloud, allowing businesses to manage and handle customer interactions, such as phone calls, emails, chats, and more, from anywhere with internet connectivity. It offers flexibility, scalability, and cost-effectiveness compared to traditional on-premises contact center systems.",
    divName: "contactCenter",
  },
  {
    question: "Cloud Contact Center Benefits ?",
    answer:
      "Cost-efficiency, scalability, flexibility, remote accessibility, and the ability to access advanced features and analytics for enhanced customer service and support.",
    divName: "benefits",
  },
  {
    question: "What is Digital Contact Center ?",
    answer:
      "Customer service and support platform that uses digital channels like email, chat, social media, and messaging apps to engage with customers, providing a more versatile and convenient means of communication compared to traditional phone-based call centers.",
    divName: "digital",
  },
  {
    question: "Benefits of Digital Channels ?",
    answer:
      "Faster communication, wider reach, cost-efficiency, convenience, and the ability to track and analyze customer interactions for improved service.",
    divName: "benefitChannel",
  },
  {
    question: "Benefits of Co-browsing ?",
    answer:
      "Co-browsing is an invaluable tool for businesses and customer support. It allows for real-time visual collaboration between agents and customers, enhancing the overall experience.",
    divName: "cobrwosing",
  },
  {
    question: "How Do I Migrate My Existing Contact Center To The Cloud?",
    answer:
      "The migration process varies depending on your current setup and requirements. Generally, it involves assessing your needs, selecting a suitable cloud contact center provider, data migration, and training for your team. We offer you guidance and support throughout the migration process.",
    divName: "migrate",
  },
  {
      question:"Can I Integrate A Cloud Contact Center With My Existing CRM Or Other Business Systems?",
      answer:"Yes, our solutions offer integration options with popular CRM platforms and other business applications to ensure a seamless flow of customer data and interaction history across systems.",
      divName:"integrate"
  }
];
