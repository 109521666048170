import logo from './logo.svg';
import { HashRouter, Route, Routes, Link, Navigate, useNavigate } from "react-router-dom";
import "./css/pressRelease.css"
import './css/Style1.css'
import './css/Style2.css'
import "./css/resposive.css"

import Career from './career/Career.js'
import Openposition from './Openposition';
import Contact from './pages/Contact.js'
import Login from './pages/Login';
import About from './pages/About';
import Landing from './pages/Landing';
import Feature from './pages/Feature';
import Product from './pages/Product';
import Faq from './pages/Faq';
import Datatable from './pages/Datatable'
import DataLogin from './pages/DataLogin';
import Enquiries from './pages/Enquiries';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import SmartSurvey from './pages/SmartSurvey';
import TicketingSystem from './pages/TicketingSystem';
import AvayaCX from './pages/AvayaCX';
import AvayaCxImp from './pages/AvayaCxImp';
import { Helmet } from 'react-helmet';
import ProductNew from './pages/products/Product.js';
import Dcc from './pages/products/inner-pages/Dcc.js';
import Ai from './pages/products/inner-pages/Ai.js';
import SmartRule from './pages/products/inner-pages/SmartRule.js';
import DigitalWorkSpace from './pages/products/inner-pages/DigitalWorkSpace.js';
import CoBrowser from './pages/products/inner-pages/CoBrowser.js';
import SocialMediaConnector from './pages/products/inner-pages/SocialMediaConnector.js';
import SmartSurveyNew from './pages/products/inner-pages/SmartSurveyNew.js';
import Ticketing from './pages/products/inner-pages/Ticketing.js';
import Crm from './pages/products/inner-pages/Crm.js';
import CallbackManager from './pages/products/inner-pages/CallbackManager.js';
import CallAnalytics from './pages/products/inner-pages/CallAnalytics.js';
import QualityMonitor from './pages/products/inner-pages/QualityMonitor.js';
import SocialEngage from './pages/products/inner-pages/SocialEngage.js';
import SmsSolution from './pages/products/inner-pages/SmsSolution.js';
import VideoKyc from './pages/products/inner-pages/VideoKyc.js';
import OutBound from './pages/products/inner-pages/OutBound.js';
import PressRelease from './pages/PressRelease.js';
import PowerBi from './pages/products/inner-pages/PowerBi.js';

function App() {
  return (
    <div>
      <Helmet>
        <link rel="canonical" href="https://inaipiapp.com/" />
      </Helmet>
      <HashRouter>
        <Routes >
          <Route path="/" element={<Landing />}></Route>
          <Route path="/career" element={<Career />}></Route>
          <Route path="/openposition" element={<Openposition />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/feature" element={<Feature />}></Route>
          <Route path="/product-old" element={<Product />}></Route>
          {/* products */}
          <Route path="/solutions" element={<ProductNew/>}></Route>
          <Route path="/digital-contact-center" element={<Dcc/>}></Route>
          <Route path="/ai-chatbot" element={<Ai/>}></Route>
          <Route path="/smart-x" element={<SmartRule/>}></Route>
          <Route path="/digital-work-space" element={<DigitalWorkSpace/>}></Route>
          <Route path="/co-browsing" element={<CoBrowser/>}></Route>
          <Route path="/social-media-connector" element={<SocialMediaConnector/>}></Route>
          <Route path="/smart-survey" element={<SmartSurveyNew/>}></Route>
          <Route path="/ticketing-system" element={<Ticketing/>}></Route>
          <Route path="/crm-connector" element={<Crm/>}></Route>
          <Route path="/call-back-manager" element={<CallbackManager/>}></Route>
          <Route path="/call-analytics" element={<CallAnalytics/>}></Route>
          <Route path="/quality-monitoring" element={<QualityMonitor/>}></Route>
          <Route path="/socialmedia-engagement-platform" element={<SocialEngage/>}></Route>
          <Route path="/sms-solutions" element={<SmsSolution/>}></Route>
          <Route path="/video-kyc" element={<VideoKyc/>}></Route>
          <Route path="/out-bound-dialer" element={<OutBound/>}></Route>
          <Route path="/power-bi-reports" element={<PowerBi/>}></Route>

          {/* products */}

          <Route path="/faq" element={<Faq />}></Route>
          <Route path="/press-releases" element={<PressRelease />}></Route>
          <Route path="/enquiries" element={<Enquiries />}></Route>
          <Route path="/data-login" element={<DataLogin />}></Route>
          {/* //<Route path="/smart-survey" element={<SmartSurvey />}></Route> */}
          {/* <Route path="/ticketing-system" element={<TicketingSystem />}></Route> */}
          <Route path="/Avaya_CX" element={<AvayaCX />}></Route>
          <Route path="/ImperiumAvaya_CX" element={<AvayaCxImp />}></Route>
          <Route path="/sitemap.xml" component={() => null} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
