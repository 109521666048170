import React from 'react'

function Presense() {
  return (
    <div className="mt-[90px] top_section">
    <h2 className="text-center">Our Presence</h2>
    <div className="mt-4 grid grid-flow-row md:grid-cols-3  grid-cols-1 md:gap-x-6 gap-y-6 place-content-center place-items-center">
      
        <div className="presenceCard">
            <div className="cardH">UAE</div>
            <div className="cardP text-center">
            Unit: 606, Fortune Executive Tower, Cluster T, Jumeirah Lake Towers, P.O.Box: 124097, Dubai, UAE
            <br/>+97144288444 / +97145646398
            </div>
         </div>
        <div className="presenceCard">
            <div className="cardH">Bengaluru</div>
            <div className="cardP text-center">
            #870, 1st Floor, Geethanjali House,BDA Layout, New Thippassandra, Bengaluru, Karnataka 560075
            </div>
        </div>
        <div className="presenceCard">
            <div className="cardH">Chennai</div>
            <div className="cardP text-center">
                47/2 Ashok Nagar, 53rd Street,Indira Colony, Chennai. <span className=" whitespace-nowrap">Tamil Nadu</span> 600083
            </div>
        </div>
    </div>
</div>
  )
}

export default Presense